.whitepaper-container {
  width: 100%;
  background-image: linear-gradient(#004c67, #026386, #03a6e6);
  color: white;
  margin-top: 80px;
  padding-top: 20px;

  .whitepaper {
    width: 80%;
    margin: auto;

    .content {
      .heading {
        font-weight: bold;
        font-size: 24px;
      }

      .email {
        margin: 20px 0;
        ::placeholder{
          color: rgb(175, 184, 202);
        }
        input {
          border: none;
          border-radius: 10px;
          width: calc(100% - 20px);
          height: 30px;
          background-color: #ffffff26;
          padding: 5px 10px;
          color: white;
        }
      }

      .button {
        margin-bottom: 20px;
        position: relative;

        button {
          border: none;
          background-color: #003b509a;
          color: white;
          border-radius: 10px;
          height: 40px;
          width: 100%;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
        }

        .progress {
          color: #4caf50;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -15px;
          margin-left: -12px;
        }

        // .disabledButton{
        //   background-color: gray;
        //   cursor: not-allowed;
        // }
      }

      .message {
        display: flex;
        margin-bottom: 20px;

        .icon {
          font-size: 25px;
          color: #29d089;
        }

        .text {
          margin-left: 20px;
          color: #acacac;
        }
      }
    }

    .image-container {
      position: relative;
    }

    .whitepaper-us-captcha{
      margin-bottom: 12px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .whitepaper-container {
    .whitepaper {
      .image-container {
        height: 300px;

        .images {
          .background-image {
            img {
              height: 300px;
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
            }
          }

          .foreground-image {
            img {
              height: 150px;
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .whitepaper-container {
    .whitepaper {
      display: flex;

      .content {
        flex: 1;
        margin-left: 80px;

        .heading {
          font-weight: bold;
          font-size: 24px;
        }

        .email {
          margin: 20px 0;

          input {
            width: 80%;
          }
        }

        .button {
          margin-bottom: 40px;
          width: 40%;
        }

        .message {
          min-height: 50px;
          margin-bottom: 50px;
        }
      }

      .image-container {
        flex: 1;
        .images {
          .background-image {
            img {
              width: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
            }
          }

          .foreground-image {
            img {
              width: 20%;
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
            }
          }
        }
      }
    }
  }
}
