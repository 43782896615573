.help-container {
  position: relative;
  margin: 100px auto;
  padding: 80px 0;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  box-shadow: 50px 50px 99px #2929290f;

  .help {
    margin: 0 10px;

    .upper-section {
      display: flex;

      .video {
        position: relative;

        .thumbnail {
          border-radius: 10px;
          border: 1px solid black;
          height: 240px;
          width: 400px;
          background-color: black;
          cursor: pointer;
        }

        .play-button-background {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -54%);
          background-color: white;
          z-index: 1;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          opacity: 0.3;
          cursor: pointer;
        }

        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 50px;
          color: white;
          z-index: 2;
          cursor: pointer;
        }

        .bottom {
          position: absolute;
          left: 50%;
          bottom: -50px;
          transform: translateX(-50%);

          img {
            width: 150px;
          }
        }

        .side {
          position: absolute;
          left: 100%;
          bottom: 25%;

          .hr {
            border: 1px solid black;
            width: 40px;
          }
          .square {
            background-color: black;
            padding: 8px 8px 8px 8px;
            position: absolute;
            left: 40px;
            top: -12px;

            .circle {
              background-color: white;
              border-radius: 50%;
              height: 10px;
              width: 10px;
            }
          }
        }
      }

      .heading {
        margin: auto;
        position: relative;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        img {
          position: absolute;
          transform: translate(-50%, -40%);
          width: 200px;
        }
      }
    }

    .content {
      line-height: 30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .help-container {
    .help {
      margin: 0 50px;
      .upper-section {
        .heading {
          font-size: 30px;
        }
      }

      .content {
        margin-top: 30px;
      }
    }
  }
}
