.contact-us-container {
  position: relative;
  margin: 25px auto;
  padding: 20px 0;
  width: 80%;
  border-radius: 10px;

  .contact-us {
    margin: 0 10px;

    .heading {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      img {
        position: absolute;
        transform: translate(-50%, -40%);
        width: 200px;
      }
    }

    .content {
      .inner-grid{
        width: 100%;
      }
      .form-input {
        .required {
          color: #f46632;
        }

        label {
          font-weight: bold;
        }

        input,
        select {
          margin-top: 10px;
          width: calc(100% - 20px);
          box-shadow: 40px 40px 99px #acacac29;
          border: 1px solid #cccccc;
          border-radius: 10px;
          background-color: #ffffff;
          min-height: 25px;
          padding: 5px 10px;
        }

        select {
          margin-top: 10px;
          width: 100%;
          box-shadow: 40px 40px 99px #acacac29;
          border: 1px solid #cccccc;
          border-radius: 10px;
          background-color: #ffffff;
          min-height: 25px;
          padding: 10px;
        }
      }

      .contact-us-captcha{
        margin-top: 10px;
      }

      .button {
        text-align: center;
        margin-top: 50px;
        position: relative;

        .progress {
          color: #4caf50;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -12px;
          margin-left: -12px;
        }

        button {
          cursor: pointer;
          border: none;
          background-color: #03aae7;
          color: white;
          font-weight: bold;
          border-radius: 10px;
          min-height: 40px;
          width: 100%;
        }

        button.loading {
          cursor: initial;
          background-color: #cccccc;
        }
      }

      .message {
        display: flex;
        margin-top: 20px;
        min-height: 20px;
        text-align: center;

        .icon {
          font-size: 25px;
          color: #29d089;
        }

        .text {
          margin-left: 20px;
          color: #acacac;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .stretch {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .contact-us-container {
    .contact-us {
      margin: 0 50px;
      .content {
        .button {
          button {
            width: 25%;
          }
        }
      }

      .message {
        margin-top: 50px;
        min-height: 50px;
      }
    }
  }
}
