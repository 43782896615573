.top-nav {
  width: 80%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    // width: 16%;
    height: auto;
    min-width: 141px;
    max-width: 141px;
    min-height: 32px;
    padding: 8px;
  }

  ul {
    flex: 10;
    list-style-type: none;
    display: flex;
    margin: 15px 0;

    li {
      margin: 0 30px;
      cursor: pointer;
    }
  }

  .contact-info {
    display: flex;
    align-items: center;
    font-size: 14px;
    div:last-child {
      margin-left: 10px;
      padding-left: 10px;
      position: relative;
      top: 1px;
      &::after {
        position: absolute;
        content: " ";
        left: 0;
        top: 3px;
        width: 2px;
        height: 12px;
        border-left: 1px solid white;
      }
    }
  }

  .right-buttons {
    display: flex;

    .resource-container {
      position: relative;
      a {
        text-decoration: none;
      }
      button {
        margin-left: 0;
        position: relative;
        z-index: 3;
      }
      ul {
        position: absolute;
        z-index: 2;
        top: 36px;
        left: 0;
        width: 100%;
        box-shadow: 0px 10px 12px #00000029;
        margin: 0;
        padding: 0;
        // display: flex;
        flex-direction: column;
        background-color: white;
        z-index: 2;
        display: none;
        li {
          color: #014f6b;
          font-size: 12px;
          margin: 0;
          padding: 5px 10px;
          margin: 2px 0;
          white-space: nowrap;
          border-bottom: 1px solid #eee;
          svg {
            position: relative;
            top: 0;
            left: 3px;
            height: 10px;
            margin-left: 3px;
          }
          &.guide-menu {
            position: relative;
            ul {
              display: none;
              // display: block;
              width: auto;
              position: absolute;
              left: 100%;
              top: 0;
              margin-left: 1px;
              li {
                border-bottom: 1px solid #eee;
                &:last-child {
                  border: none;
                }
              }
            }
            &:hover {
              ul {
                display: block;
              }
            }
          }
          &:last-child {
            border: none;
          }
          &:hover {
            // background-color: #014f6b;
            // color: white;
            text-decoration: underline;
          }
        }
      }
      &.open {
        button {
          color: white;
          background-color: #014f6b;
        }
        > ul {
          display: flex;
        }
      }
    }
  }

  button {
    flex: 1;
    color: #014f6b;
    border: none;
    border-radius: 8px;
    height: 30px;
    margin: 10px 0;
    padding: 5px 15px;
    margin-left: 10px;
    cursor: pointer;
    background-color: white;
    box-shadow: 1px 1px rgb(255, 255, 255, 0.1);
    min-width: 75px;
    border: 1px solid white;

    svg {
      position: relative;
      top: 1px;
      left: 3px;
      /* width: 10px; */
      height: 10px;
      margin-left: 10px;
    }
    &:hover {
      color: white;
      background-color: #014f6b;
    }
  }
}
@media only screen and (max-width: 767px) {
  .top-nav {
    .contact-info {
      font-size: 9px;
      margin-top: 1px;
      div:last-child {
        // margin-left: 10px;
        // padding-left: 10px;
        // position: relative;
        // top: 1px;
        &::after {
          top: 0px;
        }
      }
    }
    // img {
    //   width: 30%;
    //   min-width: 50px;
    //   height: 10%;
    //   min-height: 22px;
    //   margin-top: 3px;
    // }
  }
}

// @media only screen and /*(min-width:767px)  and */ (max-width: 900px) {
//   .top-nav{
//     img {
//     width: 22%;
//     height: 10%;
//     min-width: 90px;
//     min-height: 38px;
//     }
//   }
// }

@media only screen and (max-width: 900px) {
  .top-nav {
    flex-direction: column;
    .right-buttons {
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
      button {
        min-width: 110px;
      }
    }
    .contact-info {
      margin-top: 10px;
    }
  }
}
