.footer-container {
  width: 100%;
  background-image: linear-gradient(#004c67, #026386, #03a6e6);
  color: white;
  padding: 10px 0;

  .footer {
    .social {
      margin: auto;
      text-align: center;

      svg {
        margin: 0 10px;
        cursor: pointer;
      }
    }

    .copyright {
      text-align: center;

      .logo {
        img {
          width: 21%;
          height: auto;
          min-width: 141px;
          min-height: 32px;
        }
      }
    }

    .contact {
      text-align: center;
    }
  }

  .text {
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer-container {
    .footer {
      display: flex;

      .social {
        flex: 1;
        order: 1;

        svg {
          margin: 0 10px;
          cursor: pointer;
        }
      }

      .copyright {
        flex: 2;
        order: 2;
        text-align: center;

        .logo {
          margin-top: 20px;
        }
      }

      .contact {
        flex: 1;
        order: 3;
        margin-top: auto;
        text-align: center;
        margin-right: 10px;

        p {
          margin-bottom: auto;
        }
      }
    }
  }
}
