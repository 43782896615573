.challenges-container {
  position: relative;
  margin: 50px auto;
  padding-top: 20px;
  width: 80%;

  .heading {
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    img {
      position: absolute;
      transform: translate(-50%, -40%);
      width: 65%;
    }
  }

  .content {
    margin-top: 100px;

    .challenge {
      margin: 25px 0;

      .challenge-heading {
        font-weight: bold;
        padding-left: 25px;
      }

      .text {
        .point {
          display: flex;
          margin: 25px 0;

          .icon {
            color: #29d089;
            font-size: 25px;
          }

          .point-text {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .challenges-container {
    .content {
      .challenge {
        .text {
          .point {
            .icon {
              flex: 1;
              padding-left: 20px;
            }

            .point-text {
              flex: 11;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
}
