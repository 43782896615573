.overview-container {
  position: relative;
  margin: 50px auto;
  padding: 60px 0;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  box-shadow: 50px 50px 99px #2929290f;

  .overview {
    margin: 0 10px;

    .heading {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 24px;

      // img {
      //   position: absolute;
      //   transform: translate(-50%, -30%);
      //   width: 350px;
      // }

      p {
        position: relative;
        // z-index: 2;
      }
    }

    .block-container {
      display: flex;
      gap: 30px;
      margin-top: 62px;

      .video-block {
        background-color: #03a6e6;
        border-radius: 10px;
        color: white;
        padding: 30px 40px 20px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 40px;
          height: 40px;
        }

        h2 {
          font-size: 24px;
          margin-bottom: 30px;
          position: relative;
          text-align: center;

          &::after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-25px);
            width: 50px;
            height: 3px;
            background-color: white;
          }
        }

        p {
          font-size: 14px;
          // margin: 0;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }

  .video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    &.show {
      display: flex;
    }

    // .video-frame {
    //   display: contents;
    // }

    .cross-icon {
      position: relative;
      bottom: 39%;
      left: 1%;
      border-radius: 75%;
      background-color: #e0e0e0;
      /* Adjust the color as needed */
      padding: 1px;

      //   color: rgba(185, 25, 25, 0.87);
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .overview-container {
    .overview {
      margin: 0 50px;

      .heading {
        font-size: 36px;
      }
      img {
        position: absolute;
        transform: translate(-50%, -30%);
        width: 350px;
      }
      .block-container {
        display: flex;
        grid-gap: 30px;
        gap: 30px;
      }
    }

    .video-container {
      .video-frame {
        display: contents;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .overview .block-container {
    display: grid;
    grid-gap: 30px;
    gap: 30px;
    flex-direction: column;
  }

  .overview-container {
    .overview {
      img {
        position: absolute;
        transform: translate(-50%, -30%);
        width: 275px;
      }
    }

    .video-container {
      padding: 0px;
      .video-frame {
        margin-left: 50px;
      }
    }
  }
}
