.features-container {
  position: relative;
  margin: 100px auto;
  padding: 80px 0;
  width: 80%;
  border-radius: 10px;

  .features {
    margin: 0 50px;

    .heading {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 30px;

      img {
        position: absolute;
        transform: translate(-50%, -40%);
        width: 50%;
      }

      p {
        text-decoration: underline;
        text-decoration-color: #03a6e6;
        text-underline-offset: 50px;
      }
    }

    .content {
      margin-top: 100px;

      .feature {
        border: 1px solid #03aae7;
        border-radius: 10px;
        position: relative;

        .top-image {
          border-radius: 50%;
          position: absolute;
          background-color: #03aae7;
          width: 80px;
          height: 80px;
          top: -40px;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 1px 12px 20px #03aae7;

          img {
            height: 40px;
            width: 40px;
            padding: 20px;
          }
        }

        .title {
          font-weight: bold;
          text-align: center;
          margin: 80px 0;
        }

        .expand-collapse {
          text-align: center;
          cursor: pointer;
          color: #03aae7;
          margin: 80px 0;
        }
      }
    }
  }
}
