.privacy-security-container {
  position: relative;
  margin: 50px auto;
  padding: 60px 0;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  box-shadow: 50px 50px 99px #2929290f;

  .privacy-security {
    margin: 0 10px;

    .heading {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      img {
        position: absolute;
        transform: translate(-50%, -40%);
        width: 200px;
      }
    }

    .tab-group {
      width: fit-content;
      margin: auto;
      display: flex;
      text-align: center;
      font-weight: bold;
      margin-top: 50px;

      .tab {
        border: 1px solid black;
        padding: 10px;
        min-width: 80px;
        cursor: pointer;
      }

      .tab:first-child {
        border-radius: 10px 0 0 10px;
      }

      .tab:last-child {
        border-radius: 0 10px 10px 0;
      }

      .active {
        background-color: black;
        color: white;
      }
    }

    .content {
      margin-top: 50px;

      .image {
        flex: 1;
        padding-top: 25px;
        img {
          width: 100%;
        }
      }

      .text {
        flex: 2;
        line-height: 30px;

        .link {
          text-decoration: underline;
          color: #03aae7;
          cursor: pointer;
        }

        .link:hover {
          color: #038ce7;
        }

        .button {
          color: #03aae7;

          span {
            cursor: pointer;

            .icon {
              transform: translateY(1px);
              margin-left: 5px;
            }
          }
        }
        .request-wallpaper-pointer{
          cursor: pointer;
          color: #0000EE;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .privacy-security-container {
    .privacy-security {
      margin: 0 50px;

      .heading {
        font-size: 30px;
      }

      .tab-group {
        margin-top: 80px;

        .tab {
          min-width: 300px;
        }
      }

      .content {
        display: flex;
        margin-top: 80px;

        .text {
          margin-left: 50px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .privacy-security-container {
    .privacy-security {
      .tab-group {
        .tab {
          min-width: 200px;
        }
      }
    }
  }
}