.intro-container {
  position: relative;
  background-color: white;
  width: 80%;
  border-radius: 10px;
  box-shadow: 50px 50px 99px #2929290f;
  margin: 155px auto 50px;

  .intro {
    margin: 0 10px;
    .text-container {
      .heading {
        font-size: 30px;
        font-weight: bold;

        .underline {
          text-decoration: underline;
          text-decoration-color: #03a6e6;
          text-underline-offset: 10px;
        }
      }

      .secure {
        margin-bottom: 30px;
      }

      .text {
        line-height: 30px;
        p {
          .cursor-pointer {
            cursor: pointer;
            color: blue;
          }
        }
      }
    }

    img {
      width: 100%;
    }
  }
  .digilock-container {
    width: 90%;
    margin: auto;
    line-height: 30px;
    p {
      margin-top: 0px;
    }
    .request-section {
      display: flex;
      gap: 30px;
      .request-button {
        width: 50%;
        margin: auto;
        button {
          width: 100%;
          background-color: #03a6e6;
          border: none;
          border-radius: 10px;
          color: white;
          font-weight: bold;
          min-height: 40px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .intro-container {
    margin: 70px auto 50px;
    padding: 80px 0 40px;

    .intro {
      display: flex;
      flex-direction: row-reverse;
      margin: 0 50px;
      margin-bottom: -5px;
      .text-container {
        margin-right: 100px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .intro-container {
    margin: 156px auto 50px;
    .digilock-container {
      width: 96%;
      margin-bottom: 10px;
      button {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .intro-container {
    margin: 150px auto 50px;
  }
}
